<template>
  <div class="class-list">
    <GlobalInfoBar
      title="银行卡提现"
      content="用于审核和查看公司提现到银行卡的账单。"
    />
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <Form />
      </div>
      <Table />
    </GlobalChunk>
  </div>
</template>

<script>
import Form from "./Form";
import Table from "./Table";
import _api from "@/utils/request";
export default {
  name: "class-list",
  components: {
    Form,
    Table,
  },
  data() {
    return {
    };
  },
  created(){
  },
  methods: {
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.class-list{
}
</style>