<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="90"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import _api from "@/utils/request";
import moment from 'moment'

export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String
    },
  },
  data() {
    return {
      initData: {
        entryTime: [
          moment()
            .day(moment().day()-6)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      formItemList: [
        {
          key: "companyName",
          type: "input",
          labelName: "所属商家",
          placeholder: "请输入所属商家",
        },
        {
          key: "transferNo",
          type: "input",
          labelName: "出账单号",
          placeholder: "请输入出账单号",
        },
        {
          key: "staffName",
          type: "input",
          labelName: "提现人姓名",
          placeholder: "请输入姓名",
        },
        {
          key: 'staffRole',
          type: 'select',
          labelName: '角色',
          placeholder: '请选择角色',
          option: [
            {
              value: '01',
              label: '创建人',
            },
            {
              value: '06',
              label: '子账号',
            },
          ],
        },
        {
          key: "staffPhone",
          type: "input",
          labelName: "手机号",
          placeholder: "请输入手机号",
        },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "创建时间",
          placeholder: "请选择创建时间",
          valueFormat: "timestamp",
          clearable: true,
        },
      ],
    };
  },
  created() {
    this.handleConfirm(this.initData)
  },
  watch:{
  },
  methods: {
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let SeachParams = {};
      if (data) {
        SeachParams = {
          transferNo: data.transferNo,
          staffName: data.staffName,
          staffRole: data.staffRole,
          staffPhone: data.staffPhone,
          companyName: data.companyName,
          startTime: data.entryTime ? moment(data.entryTime[0]).format("x") : "",
          endTime: data.entryTime ? moment(data.entryTime[1]).format("x") : "",
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams. disuseLoding=() => {
          if(typeof cd ==='function'){
             cd();
          }
        },

      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
